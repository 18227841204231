// libs
import { z } from 'lib/zod-extended'
import { tw } from 'lib/tw'
import useTranslation from 'next-translate/useTranslation'
import { useSignInEmailPasswordless, useUserData, useSignOut } from '@nhost/nextjs'
// components
import { Form } from 'ui/Form'
import { InlineNotification } from 'ui/InlineNotification'
// hooks
import { useToaster, useRedirectToUrl } from 'hooks'

// views
const AuthSettings = tw.div`my-6 flex items-center justify-between`

// types
type FormValues = {
  email: string
  remember: boolean
}

export function PasswordlessLoginForm() {
  const user = useUserData()
  const redirectTo = useRedirectToUrl()

  const { t } = useTranslation('passwordless-login')

  const { openToast } = useToaster()

  const { signOut } = useSignOut()
  const { signInEmailPasswordless, isLoading, isError, error } = useSignInEmailPasswordless()

  const onSubmit = async (data: FormValues) => {
    if (user) {
      await signOut()
    }

    const { isSuccess } = await signInEmailPasswordless(data.email, {
      redirectTo,
    })

    if (isSuccess) {
      openToast({
        type: 'SUCCESS',
        title: t('successToastTitle'),
        message: t('successToastText'),
      })
    }
  }

  const schema = z.object({
    email: z.required(t('requiredFieldValidation')).email(t('emailFieldValidation')),
    remember: z.boolean(),
  })

  return (
    <Form<FormValues>
      schema={schema}
      onSubmit={onSubmit}
      disabled={isLoading}
      submitText={t('submitText')}
      action="/api/forms/passwordless-login"
    >
      <Form.Input name="email" label={t('emailField')} />
      {isError && <InlineNotification type="WARNING" message={error?.message} />}

      <AuthSettings>
        <Form.Checkbox label={t('rememberField')} name="remember" />
      </AuthSettings>
    </Form>
  )
}
