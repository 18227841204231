// libs
import { tw } from 'packages/lib/tw'

export const RGTitle = tw.span`text-base font-medium text-gray-900`

export const RGDescription = tw.p`text-sm leading-5 text-gray-500`

export const RGContainer = tw.div`mt-4 space-y-4`

export const RadioWrapper = tw.div`flex items-center`

export const RadioButton = tw.input<{ $isError: boolean }>`
  text-primary-600 h-4 w-4 ${({ $isError }) =>
    $isError ? 'border-red-500' : 'border-gray-300'} focus:ring-0
`

export const RadioButtonLabel = tw.label`ml-3 block text-sm font-medium text-gray-700`
