// libs
import { z } from 'lib/zod-extended'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useSignInEmailPassword } from '@nhost/nextjs'
// components
import { Form } from 'ui/Form'
import { InlineNotification } from 'ui/InlineNotification'

// types
type FormValues = {
  code: string
}

export function TwoFactorVerifyForm() {
  const router = useRouter()

  const { t } = useTranslation('tfa-verify')

  const { sendMfaOtp, isLoading, isError, error } = useSignInEmailPassword()

  const onSubmit = async (data: FormValues) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const { isSuccess } = await sendMfaOtp(data.code)

    if (isSuccess) {
      await router.push('/dashboard')
    }
  }

  const schema = z.object({
    code: z.required(t('requiredFieldValidation')),
  })

  return (
    <Form<FormValues>
      schema={schema}
      onSubmit={onSubmit}
      disabled={isLoading}
      submitText={t('submitText')}
      action="/api/forms/tfa-verify"
    >
      <Form.Input name="code" label={t('codeField')} />
      {isError && <InlineNotification type="WARNING" message={error?.message} />}
    </Form>
  )
}
