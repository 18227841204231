// libs
import { tw } from 'packages/lib/tw'
import { useFormContext } from 'react-hook-form'
import { HTMLAttributes, PropsWithoutRef, forwardRef, useState } from 'react'
// components
import { FieldLabel } from '../Label'
import { FieldAlert } from '../Alert'
// icons
import { EyeIcon, EyeOffIcon } from '../../icons'
// utils
import { getFormError } from '../utils'

// views
const Field = tw.input<{ $isError: boolean; $isPasswordField: boolean }>`
  ${({ $isError }) =>
    $isError
      ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
      : 'border-gray-300 focus:ring-primary-600 focus:border-primary-600'}

    ${({ $isPasswordField }) => ($isPasswordField ? 'pr-8' : '')}
    shadow-sm sm:text-sm rounded`

const FieldContainer = tw.div`form-control flex flex-col mb-6 relative`

const IconWrapper = tw.div`absolute top-8 right-2 cursor-pointer`

// types
interface FieldProps extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  /** Field name. */
  name: string
  /** Field label. */
  label?: string
  /** Field type. */
  type?: string | undefined
  /** Field type. Doesn't include radio buttons and checkboxes */
  containerProps?: PropsWithoutRef<JSX.IntrinsicElements['div']>
}

export const Input = forwardRef<HTMLInputElement, FieldProps>(
  ({ label, type = 'text', containerProps, name, ...props }, _ref): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => setShowPassword((prev) => !prev)

    const {
      register,
      formState: { errors },
      disabled,
    } = useFormContext()

    const error = getFormError(errors, name)

    const isPassword = type === 'password'

    return (
      <FieldContainer {...containerProps}>
        <FieldLabel htmlFor={name} label={label} isError={!!error} />
        <Field
          id={name}
          $isError={!!error}
          disabled={disabled}
          $isPasswordField={isPassword}
          type={showPassword ? 'text' : type}
          {...register(name)}
          {...(props as HTMLAttributes<HTMLInputElement>)}
        />

        {isPassword && (
          <IconWrapper aria-hidden="true" onClick={toggleShowPassword}>
            {showPassword ? <EyeIcon /> : <EyeOffIcon />}
          </IconWrapper>
        )}
        <FieldAlert error={error} />
      </FieldContainer>
    )
  },
)

Input.displayName = 'Form.Input'
