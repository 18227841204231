// libs
import { useFormContext } from 'react-hook-form'
import { HTMLAttributes, PropsWithoutRef } from 'react'
// components
import { FieldAlert } from '../Alert'
// views
import {
  RGTitle,
  RGContainer,
  RadioButton,
  RadioWrapper,
  RGDescription,
  RadioButtonLabel,
} from './RadioGroup.views'
// utils
import { getFormError } from '../utils'

type Option = {
  label: string
  value: string
}

interface RadioGroupProps extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  name: string
  title: string
  description?: string
  options: Option[]
}

export function RadioGroup({ name, title, description, options, ...props }: RadioGroupProps) {
  const {
    register,
    formState: { errors },
    disabled,
  } = useFormContext()

  const error = getFormError(errors, name)

  return (
    <>
      {title && <RGTitle>{title}</RGTitle>}
      {description && <RGDescription>{description}</RGDescription>}
      <RGContainer>
        {options.map((option) => (
          <RadioWrapper key={option.value}>
            <RadioButton
              name={name}
              type="radio"
              id={option.value}
              $isError={!!error}
              disabled={disabled}
              value={option.value}
              {...register(name)}
              {...(props as HTMLAttributes<HTMLInputElement>)}
            />
            <RadioButtonLabel htmlFor={option.value}>{option.label}</RadioButtonLabel>
          </RadioWrapper>
        ))}
      </RGContainer>
      <FieldAlert error={error} />
    </>
  )
}

RadioGroup.displayName = 'Form.RadioGroup'
