// libs
import { z } from 'lib/zod-extended'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useChangePassword } from '@nhost/nextjs'
// components
import { Form } from 'ui/Form'
import { InlineNotification } from 'ui/InlineNotification'

// types
type FormValues = {
  password: string
  confirm: boolean
}

export function ConfirmResetPasswordForm() {
  const router = useRouter()

  const { t } = useTranslation('reset-password-confirm')

  const { changePassword, isError, error } = useChangePassword()

  const onSubmit = async (data: FormValues) => {
    const { isSuccess } = await changePassword(data.password)

    if (isSuccess) {
      await router.push({
        pathname: '/dashboard',
      })
    }
  }

  const schema = z
    .object({
      password: z
        .required(t('requiredFieldValidation'))
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, t('passwordFieldValidation')),
      confirm: z.required(t('requiredFieldValidation')),
    })
    .refine((data) => data.password === data.confirm, {
      message: t('confirmPasswordFieldValidation'),
      path: ['confirm'],
    })

  return (
    <Form<FormValues>
      schema={schema}
      onSubmit={onSubmit}
      submitText={t('submitText')}
      action="/api/forms/reset-password/confirm"
    >
      <Form.Input
        name="password"
        type="password"
        autoComplete="new-password"
        label={t('passwordField')}
      />
      <Form.Input
        name="confirm"
        type="password"
        autoComplete="new-password"
        label={t('repeatPasswordField')}
      />
      {isError && <InlineNotification type="WARNING" message={error?.message} />}
    </Form>
  )
}
