// libs
import { tw } from 'packages/lib/tw'

export const NotificationWrapper = tw.div<{ $color: string }>`${({ $color }) =>
  $color} flex rounded-md p-4 my-6`

export const IconWrapper = tw.div`flex-shrink-0`

export const ContentWrapper = tw.div`ml-3`

export const NotificationText = tw.h3<{ $color: string }>`${({ $color }) =>
  $color} text-sm font-medium`

export const NotificationMessage = tw.div<{ $color: string }>`${({ $color }) =>
  $color} mt-2 text-sm`
