import { defaultTheme, ThemeConfig } from 'react-select'
import { StylesConfig } from 'react-select/dist/declarations/src/styles'

export const reactSelectTheme: ThemeConfig = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: '#00153d',
    primary25: defaultTheme.colors.neutral10,
    primary50: defaultTheme.colors.neutral10,
    primary75: defaultTheme.colors.neutral10,
  },
}

interface CustomStylesProps {
  isError: boolean
}

export const getCustomStyles = ({ isError }: CustomStylesProps): StylesConfig => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: '38px',
    fontSize: 14,
    borderColor: '#D1D5DB',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ':hover': {
      borderColor: '#D1D5DB',
    },
    ...(isError && {
      borderColor: 'red',
      boxShadow: ` 0 0 0 ${state.isFocused ? 1 : 0}px red`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ':hover': {
        borderColor: 'red',
      },
    }),
    ...(state.isFocused &&
      !isError && {
        borderColor: '#00153d',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ':hover': {
          borderColor: '#00153d',
        },
      }),
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 14,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 4,
  }),
})
