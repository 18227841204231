// queries
import {
  GET_USERS,
  GET_BRANCHES,
  GET_COMPANIES,
  GET_AUTH_ROLES,
  GET_INTEGRATIONS,
  UsersResponse,
  BranchesResponse,
  CompaniesResponse,
  AuthRolesResponse,
  IntegrationsResponse,
} from 'system/queries'
// components
import { AsyncSelectProps, AsyncSelect } from '../CreatableAsyncSelect'
// helpers
import {
  getUsersOptions,
  getRolesOptions,
  getBranchesOptions,
  getCompaniesOptions,
  getIntegrationsOptions,
} from './EntitySelect.helper'

// types
export enum EntitySelectTypes {
  USER = 'USER',
  ROLE = 'ROLE',
  COMPANY = 'COMPANY',
  BRANCH = 'BRANCH',
  INTEGRATION = 'INTEGRATION',
}

interface EntitySelectProps
  extends Omit<AsyncSelectProps<unknown>, 'query' | 'getOptions' | 'dataKey'> {
  type: EntitySelectTypes
}

export function EntitySelect({ type, ...rest }: EntitySelectProps): JSX.Element | null {
  switch (type) {
    case EntitySelectTypes.USER:
      return (
        <AsyncSelect<UsersResponse>
          dataKey="users"
          query={GET_USERS}
          getOptions={getUsersOptions}
          {...rest}
        />
      )
    case EntitySelectTypes.ROLE:
      return (
        <AsyncSelect<AuthRolesResponse>
          dataKey="authRoles"
          query={GET_AUTH_ROLES}
          getOptions={getRolesOptions}
          {...rest}
        />
      )
    case EntitySelectTypes.COMPANY:
      return (
        <AsyncSelect<CompaniesResponse>
          dataKey="companies"
          query={GET_COMPANIES}
          getOptions={getCompaniesOptions}
          {...rest}
        />
      )
    case EntitySelectTypes.BRANCH:
      return (
        <AsyncSelect<BranchesResponse>
          dataKey="branches"
          query={GET_BRANCHES}
          getOptions={getBranchesOptions}
          {...rest}
        />
      )
    case EntitySelectTypes.INTEGRATION:
      return (
        <AsyncSelect<IntegrationsResponse>
          dataKey="integrations"
          query={GET_INTEGRATIONS}
          getOptions={getIntegrationsOptions}
          {...rest}
        />
      )
    default:
      return null
  }
}
