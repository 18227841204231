// libs
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm, UseFormProps } from 'react-hook-form'
import { PropsWithoutRef, ReactNode, useEffect, useMemo } from 'react'
// components
// import type { ZodType } from 'zod/lib/types'
import { Input } from './Input'
import { Select } from './Select'
import { Submit } from './Submit'
import { Checkbox } from './Checkbox'
import { RadioGroup } from './RadioGroup'
import { EntitySelect } from './Select/EntitySelect'

export interface FormProps<S>
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements['form']>, 'onSubmit'> {
  // FIX: Use ZodType instead of any
  schema: any
  action?: string
  /** Whether the entire form is disabled  * */
  disabled?: boolean
  /** All your form fields */
  children?: ReactNode
  onSubmit?: (values: S) => Promise<void> | void
  shouldReset?: boolean
  /** Text to display in the submit button */
  submitText?: string
  initialValues?: UseFormProps<S>['defaultValues']
  buttonClassNames?: string
}

export function Form<S extends Record<string, any>>({
  schema,
  action = '',
  children,
  onSubmit = async () => {},
  submitText = 'OK',
  shouldReset,
  initialValues,
  buttonClassNames,
  ...props
}: FormProps<S>): JSX.Element {
  const ctx = useForm<z.infer<typeof schema>>({
    mode: 'onBlur',
    defaultValues: initialValues,
    resolver: schema ? zodResolver(schema) : undefined,
    shouldFocusError: true,
  })

  const disabled = useMemo(() => props.disabled, [props.disabled])

  useEffect(() => {
    if (shouldReset) {
      ctx.reset(initialValues)
    }
  }, [ctx, initialValues, shouldReset])

  return (
    <FormProvider disabled={disabled} {...ctx}>
      <form
        method="post"
        action={action}
        onSubmit={
          // FIX: Remove `as any`
          ctx.handleSubmit(async (values): Promise<void> => {
            if (onSubmit) {
              await onSubmit(values)
            }
          }) as any
        }
        {...props}
      >
        <fieldset disabled={disabled}>
          {children}
          <Submit className={buttonClassNames}>{submitText}</Submit>
        </fieldset>
      </form>
    </FormProvider>
  )
}

Form.Input = Input
Form.Select = Select
Form.Checkbox = Checkbox
Form.RadioGroup = RadioGroup
Form.EntitySelect = EntitySelect

export default Form
