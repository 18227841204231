import * as zod from 'zod'
import type * as Z from 'zod'
// import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi'

export type { TypeOf as ZInfer } from 'zod'

const z = {
  ...zod,
  required: (message = 'Required') =>
    zod.string().min(1, {
      message,
    }),
}

// extendZodWithOpenApi(z)

export { z, Z }
