// libs
import { useEffect, useState } from 'react'
import Creatable from 'react-select/creatable'
import useSWRInfinite from 'swr/infinite'
import { withAsyncPaginate } from 'react-select-async-paginate'
import { Props as SelectProps } from 'react-select/dist/declarations/src'
import { useFormContext, Controller } from 'react-hook-form'
// components
import { Input } from '../components'
import { FieldAlert } from '../../Alert'
import { FieldLabel } from '../../Label'
// views
import { SelectContainer, SelectOption } from '../Select'
// themes
import { getCustomStyles, reactSelectTheme } from '../theme'
// utils
import { getFormError } from '../../utils'

// types
type Option = SelectOption & {
  isNew?: boolean
}

export interface AsyncSelectProps<T> extends SelectProps<Option, true> {
  name: string
  label?: string
  query: string
  dataKey: string
  perPage?: number
  getOptions: (data: T) => Option[]
}

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as any

export function AsyncSelect<T extends object>({
  name,
  label,
  query,
  isMulti,
  dataKey,
  perPage = 10,
  getOptions,
  ...rest
}: AsyncSelectProps<T>) {
  const {
    watch,
    control,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [search, setSearch] = useState<string | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [options, setOptions] = useState<Option[]>([])

  const prefetchValue: string = watch(`${name}Label`)

  const { size, setSize } = useSWRInfinite(
    (index, previousList) => {
      const isNoData = index !== 0 && previousList?.[dataKey].length === 0
      const isReachingEnd = previousList?.[dataKey].length < perPage

      if (isNoData || isReachingEnd) {
        return null
      }

      const variables = {
        ...(search && { search: `${search}%` }),
        limit: perPage,
        offset: index * perPage,
      }

      return [query, variables]
    },
    { revalidateFirstPage: false, initialSize: 0 },
  )

  // methods
  const changeHandler = (
    option: Option | Option[] | null,
    onChange: (value: string | string[]) => void,
  ) => {
    if (!option) return

    if (typeof onChange === 'function') {
      if (Array.isArray(option)) {
        onChange(option.map(({ value }) => value))
      } else {
        onChange(option.value)
      }
    }
  }

  const inputChangeHandler = (value: string) => {
    if (value) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }

  const optionCreateHandler = (value: string, onChange: (value: string | string[]) => void) => {
    const currentValue = getValues(name)
    const newOption = { value, label: value, isNew: true }

    setOptions((prev) => [...prev, newOption])

    if (isMulti) {
      onChange([...currentValue, newOption.value])
    } else {
      onChange(newOption.value)
    }

    setSearch(null)
  }

  const loadOptions = async (searchInput: string) => {
    setLoading(true)

    if (searchInput) {
      setSearch(searchInput)
    }

    const res = await setSize(size + 1)

    const fetchedOptions = getOptions(res?.[size])

    const isEmpty = res?.[0]?.length === 0
    const isReachingEnd = isEmpty || (res && res[res.length - 1]?.[dataKey].length < perPage)

    setOptions((prev) => [...prev, ...fetchedOptions])

    setLoading(false)
    return {
      options: fetchedOptions,
      hasMore: !isReachingEnd,
    }
  }

  const getValue = (value: string | string[] | Option[]): Option | Option[] => {
    if (isMulti && Array.isArray(value)) {
      const newOptions = (value as Option[]).filter((val) => val?.isNew)

      const filteredOptions = options.filter((o: Option) =>
        value.some((valueItem) => valueItem === o.value),
      )

      return [...filteredOptions, ...newOptions]
    }
    return options.find((o: Option) => o.value === value) as Option
  }

  const error = getFormError(errors, name)

  useEffect(() => {
    /** if you need to show default value for async select before fetch - pass to form initialValues
     additional value - ${fieldName}Label (e.g. companyId - companyIdLabel) */
    if (prefetchValue) {
      setOptions([{ label: prefetchValue, value: getValues(name) }])
    } else {
      setOptions([])
    }
  }, [getValues, name, prefetchValue])

  return (
    <SelectContainer>
      <FieldLabel label={label} isError={!!error} />
      <Controller
        name={name}
        control={control}
        defaultValue={isMulti ? [] : ''}
        render={({ field: { onChange, value, ref, ...field } }) => (
          <CreatableAsyncPaginate
            {...rest}
            {...field}
            name={name}
            selectRef={ref}
            isMulti={isMulti}
            debounceTimeout={300}
            isLoading={isLoading}
            // eslint-disable-next-line @typescript-eslint/naming-convention
            components={{ Input }}
            value={getValue(value)}
            theme={reactSelectTheme}
            loadOptions={loadOptions}
            SelectComponent={Creatable}
            onInputChange={inputChangeHandler}
            onCreateOption={(input: string) => optionCreateHandler(input, onChange)}
            onChange={(option: Option) => changeHandler(option, onChange)}
            styles={getCustomStyles({
              isError: !!error,
            })}
          />
        )}
      />
      <FieldAlert error={error} />
    </SelectContainer>
  )
}
