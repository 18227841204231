// libs
import { z } from 'lib/zod-extended'
import { useContext, useMemo, useState } from 'react'
// components
import { Form } from 'ui/Form'
// types
import { Users } from 'types/hasura'
import { EntitySelectTypes } from 'ui/Form/Select/EntitySelect'
// context
import { AdminUsersApiContext, AppSwrConfigContext } from 'providers'
// queries
import {
  UPDATE_USER,
  UPDATE_USER_ACCOUNT,
  UserUpdateResponse,
  UserAccountUpdateResponse,
} from 'queries'

// constants
const schema = z.object({
  name: z.required(),
  email: z.required().email(),
  role: z.required(),
  branch: z.string().optional(),
  companyId: z.string().nullable(),
  branchId: z.string().nullable(),
  emailVerified: z.boolean(),
  tfaEnabled: z.boolean(),
})

// types
type FormValues = {
  name: string
  email: string
  role: string | string[]
  branchId?: string
  companyId: string
  tfaEnabled: boolean
  emailVerified: boolean
}

interface UserSettingsFormProps {
  user: Users
}

export function UserSettingsForm({ user }: UserSettingsFormProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)

  const { mutate } = useContext(AdminUsersApiContext)
  const { request } = useContext(AppSwrConfigContext)

  const initialValues = useMemo(
    () => ({
      name: user.displayName,
      email: user.email,
      role: user.defaultRole,
      roleLabel: user.defaultRole,
      branchId: user.account?.branch?.id,
      branchIdLabel: user.account?.branch?.name,
      companyId: user.account?.company?.id,
      companyIdLabel: user.account?.company?.name,
      tfaEnabled: !!user.activeMfaType,
      emailVerified: user.emailVerified,
    }),
    [user],
  )

  const updateUser = async (input: FormValues) => {
    try {
      setIsLoading(true)

      const userInput = {
        userId: user.id,
        email: input.email,
        name: input.name,
        emailVerified: input.emailVerified,
        activeMfaType: input.tfaEnabled ? 'totp' : null,
        defaultRole: input.role,
      }

      const accountInput = {
        // TODO: check why account id is missing in type
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        accountId: user.account?.id,
        companyId: input.companyId,
        branchId: input.branchId,
      }

      const userUpdateResponse = await request<UserUpdateResponse>(UPDATE_USER, userInput)

      const accUpdateResponse = await request<UserAccountUpdateResponse>(
        UPDATE_USER_ACCOUNT,
        accountInput,
      )

      if (userUpdateResponse?.updateUser && accUpdateResponse?.updateAccount) {
        await mutate(
          // eslint-disable-next-line consistent-return
          (prev) => {
            if (prev?.users) {
              return {
                users: prev.users.map((item) => {
                  if (item.id === user.id) {
                    return {
                      ...user,
                      ...userUpdateResponse.updateUser,
                      account: {
                        ...accUpdateResponse.updateAccount,
                      },
                    }
                  }
                  return item
                }),
              }
            }
          },
          { revalidate: false },
        )
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(error))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form<FormValues>
      shouldReset
      schema={schema}
      submitText="Update"
      disabled={isLoading}
      onSubmit={updateUser}
      initialValues={initialValues as any}
      action="/api/forms/user-update"
      buttonClassNames="lg:max-w-[200px]"
    >
      <Form.Input name="name" label="Name" />
      <Form.Input name="email" label="Email address" />
      <Form.Checkbox name="emailVerified" label="Email Verified" />
      <Form.Checkbox name="tfaEnabled" label="2FA Enabled" />
      <Form.EntitySelect label="Company" name="companyId" type={EntitySelectTypes.COMPANY} />
      <Form.EntitySelect label="Branch" name="branchId" type={EntitySelectTypes.BRANCH} />
      <Form.EntitySelect label="Role" name="role" type={EntitySelectTypes.ROLE} />
    </Form>
  )
}
