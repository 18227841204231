// libs
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
// components
import { Loader } from '../Loader'
import { Button, ButtonProps } from '../Button'

export type SubmitProps = ButtonProps

export function Submit({ children, type = 'submit', ...props }: SubmitProps): JSX.Element {
  const { disabled } = useFormContext()

  const leftIcon = useMemo(() => (disabled ? { icon: <Loader /> } : undefined), [disabled])

  return (
    <Button
      type={type}
      leftIcon={leftIcon}
      disabled={props.disabled !== undefined ? props.disabled : disabled}
      {...props}
    >
      {children}
    </Button>
  )
}
