// libs
import { z } from 'lib/zod-extended'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useSignUpEmailPassword, useUserData, useSignOut } from '@nhost/nextjs'
// hooks
import { useToaster } from 'hooks'
// components
import { Form } from 'ui/Form'
import { InlineNotification } from 'ui/InlineNotification'

// types
type FormValues = {
  name: string
  email: string
  password: string
  confirm: string
}

export function SignUpForm() {
  const user = useUserData()
  const router = useRouter()

  const { t } = useTranslation('sign-up')

  const { openToast } = useToaster()

  const { signOut } = useSignOut()
  const { signUpEmailPassword, isLoading, isError, error } = useSignUpEmailPassword()

  const onSubmit = async (data: FormValues) => {
    if (user?.email === data.email) {
      openToast({ type: 'ERROR', message: t('errorToastText') })
      return
    }

    if (user) {
      await signOut()
    }

    const { isError: reqError } = await signUpEmailPassword(data.email, data.password, {
      // TODO: dont redirect to 2fa page for snap.companies
      redirectTo: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/sign-in/tfa/configure`,
      displayName: data.name,
    })

    if (!reqError) {
      await router.push({
        pathname: '/verify-email',
        query: {
          email: data.email,
        },
      })
    }
  }

  const schema = z
    .object({
      name: z.required(t('requiredFieldValidation')),
      email: z.required(t('requiredFieldValidation')).email(t('emailFieldValidation')),
      password: z
        .required(t('requiredFieldValidation'))
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, t('passwordFieldValidation')),
    })
    .extend({ confirm: z.required(t('requiredFieldValidation')) })
    .refine((data) => data.password === data.confirm, {
      message: t('confirmPasswordFieldValidation'),
      path: ['confirm'],
    })

  return (
    <Form<FormValues>
      schema={schema}
      onSubmit={onSubmit}
      disabled={isLoading}
      submitText={t('submitText')}
      action="/api/forms/sign-up"
    >
      <Form.Input name="name" label={t('nameField')} />
      <Form.Input name="email" label={t('emailField')} autoComplete="username" />
      <Form.Input
        name="password"
        type="password"
        label={t('passwordField')}
        autoComplete="new-password"
      />
      <Form.Input
        name="confirm"
        type="password"
        label={t('repeatPasswordField')}
        autoComplete="new-password"
      />
      {isError && <InlineNotification type="WARNING" message={error?.message} />}
    </Form>
  )
}
