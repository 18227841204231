// types
import { SelectOption } from '../Select'

export function getUsersOptions(data: any): SelectOption[] {
  return data.users.map((user: any) => ({
    label: user.displayName,
    value: user.id,
  }))
}

export function getRolesOptions(data: any): SelectOption[] {
  return data.authRoles.map(({ role }: any) => ({
    label: role,
    value: role,
  }))
}

export function getIntegrationsOptions(data: any): SelectOption[] {
  return data.integrations.map(
    (integration: any) =>
      ({
        label: integration.name,
        value: integration.id,
      } as any),
  )
}

export function getCompaniesOptions(data: any): SelectOption[] {
  return data.companies.map((company: any) => ({
    label: company.name,
    value: company.id,
  }))
}

export function getBranchesOptions(data: any): SelectOption[] {
  return data.branches.map(
    (branch: any) =>
      ({
        label: branch.name,
        value: branch.id,
      } as any),
  )
}
