// libs
import { z } from 'lib/zod-extended'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
// components
import { Form } from 'ui/Form'

// types
type FormValues = {
  code: string
}

interface TwoFactorConfigFormProps {
  isLoading: boolean
  activateMfa: (code: string) => Promise<{ isActivated: boolean }>
}

export function TwoFactorConfigForm({
  isLoading,
  activateMfa,
}: TwoFactorConfigFormProps): JSX.Element {
  const router = useRouter()

  const { t } = useTranslation('tfa-configure')

  const onSubmit = async (data: FormValues) => {
    const { isActivated } = await activateMfa(data.code)

    if (isActivated) {
      await router.push({ pathname: '/dashboard', query: { mfaEnabled: true } }, '/dashboard')
    }
  }

  const schema = z.object({
    code: z.required(t('requiredFieldValidation')),
  })

  return (
    <Form<FormValues>
      schema={schema}
      onSubmit={onSubmit}
      submitText={t('submitText')}
      disabled={isLoading}
      action="/api/forms/tfa-configure"
    >
      <Form.Input name="code" label={t('codeField')} />
    </Form>
  )
}
