// libs
import { z } from 'lib/zod-extended'
import useTranslation from 'next-translate/useTranslation'
import { useResetPassword } from '@nhost/react'
// components
import { Form } from 'ui/Form'
// hooks
import { useToaster } from 'hooks'

// types
type FormValues = {
  email: string
}

export function ResetPasswordForm() {
  const { openToast } = useToaster()

  const { t } = useTranslation('reset-password')

  const { resetPassword, isLoading } = useResetPassword()

  const onSubmit = async (data: FormValues) => {
    await resetPassword(data.email, {
      redirectTo: '/reset-password/confirm',
    })

    openToast({
      type: 'SUCCESS',
      title: t('successToastTitle'),
      message: t('successToastText'),
    })
  }

  const schema = z.object({
    email: z.required(t('requiredFieldValidation')).email(t('emailFieldValidation')),
  })

  return (
    <Form<FormValues>
      schema={schema}
      onSubmit={onSubmit}
      disabled={isLoading}
      action="/api/forms/reset"
      submitText={t('submitText')}
    >
      <Form.Input name="email" label={t('emailField')} />
    </Form>
  )
}
