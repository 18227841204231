/* eslint-disable @typescript-eslint/ban-ts-comment */
// libs
import { tw } from 'lib/tw'
import { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Creatable from 'react-select/creatable'
import ReactSelect, { Props as SelectProps } from 'react-select'
// components
import { Input } from './components'
import { FieldLabel } from '../Label'
import { FieldAlert } from '../Alert'
// themes
import { getCustomStyles, reactSelectTheme } from './theme'
// utils
import { getFormError } from '../utils'

// types
export type SelectOption = {
  label: string
  value: string
}

interface FormSelectProps extends Omit<SelectProps<SelectOption, true>, 'options'> {
  name: string
  label?: string
  options?: SelectOption[]
  isCreatable?: boolean
}

// views
export const SelectContainer = tw.div`relative flex flex-col mb-6`

export function Select({
  name,
  label,
  options = [],
  isMulti,
  isCreatable,
  ...rest
}: FormSelectProps) {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [selectOptions, setOptions] = useState<SelectOption[]>([])

  const SelectComponent = isCreatable ? Creatable : ReactSelect

  // methods
  const optionCreateHandler = (value: string, onChange: (value: string | string[]) => void) => {
    const currentValue = getValues(name)
    const newOption = { value, label: value }

    setOptions((prev) => [...prev, newOption])

    if (isMulti) {
      onChange([...currentValue, newOption.value])
    } else {
      onChange(newOption.value)
    }
  }

  const changeHandler = (
    option: SelectOption | SelectOption[] | null,
    onChange: (value: string | string[]) => void,
  ) => {
    if (!option) return

    if (typeof onChange === 'function') {
      if (Array.isArray(option)) {
        onChange(option.map(({ value }) => value))
      } else {
        onChange(option.value)
      }
    }
  }

  const getValue = (value: string | string[]) => {
    if (isMulti && Array.isArray(value)) {
      return selectOptions?.filter((o: SelectOption) =>
        value.some((valueItem) => valueItem === o.value),
      )
    }
    return selectOptions?.find((o: SelectOption) => o.value === value)
  }

  const error = getFormError(errors, name)

  useEffect(() => {
    setOptions(options)
  }, [options])

  return (
    <SelectContainer>
      <FieldLabel label={label} isError={!!error} />
      <Controller
        name={name}
        defaultValue=""
        control={control}
        render={({ field: { onChange, value, ...field } }) => (
          // FIXME: type not working run `yarn build`
          // @ts-ignore
          <SelectComponent
            {...rest}
            {...field}
            name={name}
            isMulti={isMulti}
            options={selectOptions}
            // eslint-disable-next-line @typescript-eslint/naming-convention
            components={{ Input }}
            value={getValue(value)}
            theme={reactSelectTheme}
            styles={getCustomStyles({ isError: !!error })}
            // FIXME: SelectOption type not working run `yarn build`
            onChange={(option: any) => changeHandler(option, onChange)}
            // onChange={(option: SelectOption) => changeHandler(option, onChange)}
            onCreateOption={(input) => optionCreateHandler(input, onChange)}
          />
        )}
      />

      <FieldAlert error={error} />
    </SelectContainer>
  )
}

Select.displayName = 'Form.Select'
