// libs
import { z } from 'lib/zod-extended'
import { tw } from 'lib/tw'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useSignInEmailPassword, useSignOut, useUserData } from '@nhost/nextjs'
// hooks
import { useRedirectToUrl } from 'hooks'
// components
import { Form } from 'ui/Form'
import { Link } from 'ui/Link'
import { InlineNotification } from 'ui/InlineNotification'

// views
const AuthSettings = tw.div`my-6 flex items-baseline justify-between`
const LinkText = tw.span`text-sm font-medium text-primary-100 hover:opacity-80`

// types
type FormValues = {
  email: string
  password: string
  remember: boolean
}

export function SignInForm() {
  const user = useUserData()
  const router = useRouter()
  const redirectTo = useRedirectToUrl()

  const { t } = useTranslation('sign-in')

  const { signOut } = useSignOut()
  const { signInEmailPassword, isLoading, isError, error } = useSignInEmailPassword()

  const onSubmit = async (data: FormValues) => {
    if (user) {
      await signOut()
    }

    const {
      needsMfaOtp,
      isError: reqError,
      needsEmailVerification,
    } = await signInEmailPassword(data.email, data.password)

    if (reqError) {
      return
    }

    if (needsEmailVerification) {
      await router.push({
        pathname: '/verify-email',
        query: {
          email: data.email,
        },
      })
      return
    }

    if (needsMfaOtp) {
      await router.push({
        pathname: '/sign-in/tfa/verify',
      })
      return
    }

    await router.push({ pathname: redirectTo })
  }

  const schema = z.object({
    email: z.required(t('requiredFieldValidation')).email(t('emailFieldValidation')),
    password: z
      .required(t('requiredFieldValidation'))
      .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, t('passwordFieldValidation')),
    remember: z.boolean(),
  })

  return (
    <Form<FormValues>
      schema={schema}
      onSubmit={onSubmit}
      disabled={isLoading}
      submitText={t('submitText')}
      action="/api/forms/sign-in"
    >
      <Form.Input name="email" label={t('emailField')} autoComplete="username" />
      <Form.Input
        name="password"
        label={t('passwordField')}
        type="password"
        autoComplete="current-password"
      />
      {isError && <InlineNotification type="WARNING" message={error?.message} />}

      <AuthSettings>
        <Form.Checkbox label={t('rememberField')} name="remember" />

        <Link href="/reset-password">
          <LinkText>{t('passwordQuestion')}</LinkText>
        </Link>
      </AuthSettings>
    </Form>
  )
}
