import { tw } from 'packages/lib/tw'

const AlertText = tw.span`text-red-600 text-xs`

interface FieldAlertProps {
  error?: string
}

export function FieldAlert({ error }: FieldAlertProps): JSX.Element | null {
  if (!error) return null

  return <AlertText>{error}</AlertText>
}
