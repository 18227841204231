// libs
import { useFormContext } from 'react-hook-form'
import { HTMLAttributes, PropsWithoutRef } from 'react'
// components
import { FieldAlert } from '../Alert'
// utils
import { getFormError } from '../utils'
// views
import {
  Input,
  CheckboxLabel,
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxDescription,
  CheckboxTextWrapper,
} from './Checkbox.views'

// types
interface CheckboxProps extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  name: string
  label: string
  description?: string
}

export function Checkbox({ name, label, description, ...props }: CheckboxProps) {
  const {
    register,
    formState: { errors },
    disabled,
  } = useFormContext()

  const error = getFormError(errors, name)

  return (
    <>
      <CheckboxContainer>
        <CheckboxWrapper>
          <Input
            id={name}
            name={name}
            type="checkbox"
            $isError={!!error}
            disabled={disabled}
            {...register(name)}
            {...(props as HTMLAttributes<HTMLInputElement>)}
          />
        </CheckboxWrapper>
        <CheckboxTextWrapper>
          <CheckboxLabel htmlFor={name}>{label}</CheckboxLabel>
          {description && <CheckboxDescription>{description}</CheckboxDescription>}
        </CheckboxTextWrapper>
      </CheckboxContainer>
      <FieldAlert error={error} />
    </>
  )
}

Checkbox.displayName = 'Form.Checkbox'
