import { PropsWithoutRef, useMemo } from 'react'
// libs
import { tw } from 'packages/lib/tw'

const Label = tw.label`text-sm font-medium text-gray-700 mb-1`

interface FieldLabelProps extends PropsWithoutRef<JSX.IntrinsicElements['label']> {
  label?: string
  isError: boolean
}

export function FieldLabel({ label, isError, ...props }: FieldLabelProps): JSX.Element | null {
  const labelClassName = useMemo(() => {
    const classes: string[] = []

    if (isError) {
      classes.push('text-error')
    }

    return classes.join(' ')
  }, [isError])

  if (!label) return null

  return (
    <Label className={labelClassName} {...props}>
      {label}
    </Label>
  )
}
