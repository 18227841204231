// icons
import { NotificationIcon } from './NotificationIcon'
// views
import {
  IconWrapper,
  ContentWrapper,
  NotificationText,
  NotificationWrapper,
} from './InlineNotification.views'
// types
import { InlineNotificationProps } from './InlineNotification.types'

// constants
const colors = {
  info: { bg: 'bg-blue-50', text: 'text-blue-700' },
  success: { bg: 'bg-green-50', text: 'text-green-700' },
  warning: { bg: 'bg-red-50', text: 'text-red-700' },
  attention: { bg: 'bg-yellow-50', text: 'text-yellow-700' },
}

export function InlineNotification({ type, message }: InlineNotificationProps): JSX.Element | null {
  if (!message) {
    return null
  }

  const colorType = type.toLowerCase() as keyof typeof colors
  const bgColor: string = colors[colorType]?.bg
  const titleColor: string = colors[colorType]?.text

  return (
    <NotificationWrapper $color={bgColor}>
      <IconWrapper>
        <NotificationIcon type={type} />
      </IconWrapper>
      <ContentWrapper>
        <NotificationText $color={titleColor}>{message}</NotificationText>
      </ContentWrapper>
    </NotificationWrapper>
  )
}
