// libs
import { z } from 'lib/zod-extended'
import useSWR from 'swr'
import { useContext, useMemo, useState } from 'react'
// components
import { Form } from 'ui/Form'
// types
import { Company } from 'types/hasura'
// context
import { AppSwrConfigContext, AdminCompaniesApiContext } from 'providers'
// queries
import { AuthRolesResponse, GET_AUTH_ROLES, UPDATE_COMPANY, UpdateCompanyResponse } from 'queries'

// constants
const schema = z.object({
  name: z.required(),
  domains: z.string().array(),
  settings: z.object({
    apiRoles: z.string().array(),
    userRoles: z.string().array(),
    defaultUserRole: z.string(),
  }),
})

// types
type FormValues = {
  displayName: string
  domains: string[]
  settings: {
    apiRoles: string[]
    userRoles: string[]
    defaultUserRole: string[]
  }
}

interface UserSettingsFormProps {
  company: Company
}

export function CompanySettingsForm({ company }: UserSettingsFormProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)

  const { mutate } = useContext(AdminCompaniesApiContext)
  const { request } = useContext(AppSwrConfigContext)

  const { data } = useSWR<AuthRolesResponse>(GET_AUTH_ROLES, { revalidateOnFocus: false })

  const initialValues = useMemo(
    () => ({
      name: company.name,
      domains: company.domains,
      settings: company.settings,
    }),
    [company],
  )

  const updateCompany = async (input: FormValues) => {
    try {
      setIsLoading(true)

      const variables = {
        companyId: company.id,
        ...input,
      }

      const response = await request<UpdateCompanyResponse>(UPDATE_COMPANY, variables)

      if (response?.updateCompany) {
        await mutate(
          // eslint-disable-next-line consistent-return
          (prev) => {
            if (prev?.companies) {
              return {
                companies: prev.companies.map((item) => {
                  if (item.id === company.id) {
                    return {
                      ...company,
                      ...response.updateCompany,
                    }
                  }
                  return item
                }),
              }
            }
          },
          { revalidate: false },
        )
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(error))
    } finally {
      setIsLoading(false)
    }
  }

  const companyDomains = company.domains.map((domain: string) => ({
    label: domain,
    value: domain,
  }))

  const roles = data?.authRoles.map(({ role }) => ({ label: role, value: role }))

  return (
    <Form<FormValues>
      shouldReset
      schema={schema}
      submitText="Update"
      disabled={isLoading}
      onSubmit={updateCompany}
      initialValues={initialValues}
      action="/api/forms/company-update"
      buttonClassNames="lg:max-w-[200px]"
    >
      <Form.Input name="name" label="Name" />
      <Form.Select name="domains" label="Domains" isCreatable isMulti options={companyDomains} />
      <Form.Select isMulti isCreatable label="API Roles" name="settings.apiRoles" options={roles} />
      <Form.Select
        isMulti
        isCreatable
        options={roles}
        label="User Roles"
        name="settings.userRoles"
      />
      <Form.Select
        isCreatable
        options={roles}
        label="Default User Role"
        name="settings.defaultUserRole"
      />
    </Form>
  )
}
