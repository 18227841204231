// libs
import { tw } from 'packages/lib/tw'

export const CheckboxContainer = tw.div`relative flex items-start mb-6`

export const CheckboxWrapper = tw.div`flex h-5 items-center`

export const Input = tw.input<{ $isError: boolean }>`
  ${({ $isError }) =>
    $isError
      ? 'border-red-500'
      : 'border-gray-300'} text-primary-600 h-4 w-4 rounded focus:ring-0 focus:ring-offset-0
`

export const CheckboxTextWrapper = tw.div`ml-3 text-sm`

export const CheckboxLabel = tw.label`font-medium text-gray-700`

export const CheckboxDescription = tw.p`text-gray-500`
